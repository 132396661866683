<template>
  <div class="fullscreen bg-primary text-white text-center q-pa-md flex flex-center">
    <div>
      <div style="font-size: 30vh">
        404
      </div>

      <div class="text-h2" style="opacity:.4">
        صفحه مورد نظر یافت نشد
      </div>

      <q-btn
        class="q-mt-xl"
        color="white"
        text-color="primary"
        unelevated
        to="/"
        label="صفحه اصلی"
        no-caps
      />
    </div>
  </div>
</template>

<script>
import {useMeta} from "quasar";

export default ({
  name: 'Error404',
  async preFetch({store, currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath}) {
    await store.dispatch('getMeta', {pageUrl: 'https://www.hamiane.com/404'})
    let meta = {}
    meta.title = store.state.preFetch?.pageMeta?.metaTitle
    meta.description = store.state.preFetch?.pageMeta?.metaDescription
    return await store.dispatch('setMeta', meta)
  },
  async mounted() {
    useMeta(this.$store.state.meta)
  }
})
</script>
